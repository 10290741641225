import marketplace_products from '../../common/data/gifts';
import topics from '../../common/data/topics';
import links from './links';
import testimonials from './testimonials';

const data = {
  gifts: marketplace_products,
  links,
  testimonials,
  topics,
};

export default data;
