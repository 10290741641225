import testimonial1Large from '../../../images/marketing/testimonials/testimonial-1-large.svg';
import testimonial1Small from '../../../images/marketing/testimonials/testimonial-1-small.png';
import testimonial2Large from '../../../images/marketing/testimonials/testimonial-2-large.svg';
import testimonial2Small from '../../../images/marketing/testimonials/testimonial-2-small.png';
import testimonial3Large from '../../../images/marketing/testimonials/testimonial-3-large.svg';
import testimonial3Small from '../../../images/marketing/testimonials/testimonial-3-small.png';
import testimonial4Large from '../../../images/marketing/testimonials/testimonial-4-large.svg';
import testimonial4Small from '../../../images/marketing/testimonials/testimonial-4-small.png';

const data = [
  {
    imageLarge: testimonial1Large,
    imageSmall: testimonial1Small,
    alt: 'Amazing facial',
    testimonial:
      'My friends recently sent me a group card with a giftcard for a facial at Heyday and it was fantastic! The nicest gift of self-care that I wouldn’t never done for myself.',
    user: 'Anne - New York',
  },
  {
    imageLarge: testimonial2Large,
    imageSmall: testimonial2Small,
    alt: 'Amazing food gift',
    testimonial:
      'I recently moved cross country and have been feeling homesick so my family sent me a card with bagels from my favorite deli in NYC using. BEST GIFT EVER!!!',
    user: 'Daniel - San Francisco',
  },
  {
    imageLarge: testimonial3Large,
    imageSmall: testimonial3Small,
    alt: 'Amazing Airbnb gift',
    testimonial:
      'I received this great Airbnb gift from my friends, and WOW! This made me so emotional️, it was so unexpected and sweet that they put it together!',
    user: 'Sarah - Los Angeles',
  },
  {
    imageLarge: testimonial4Large,
    imageSmall: testimonial4Small,
    alt: 'Amazing Olive oil',
    testimonial:
      'My coworkers gifted me a set of Brightland olive oils and it’s safe to say I’m OBSESSED. Not only are they beautiful but delicious too!',
    user: 'Tanya - Houston',
  },
];

export default data;
